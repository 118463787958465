<template>
  <div class="ingredients-table mt-4">
    <app-table
      :loading="loading"
      :data="data ? data : []"
      :fields="fields"
      :rules="rules"
      @reload="changePage"
      @edit="handleEdit"
      @delete="handleDelete"
    />
    <ingredient-modal :show.sync="modal.show" :ingredient="ingredient" actions="edit" />
  </div>
</template>

<script>
import { AppTable } from '@/app/shared/components';
import { mapActions, mapState } from 'vuex';
import { FETCH_INGREDIENTS, DELETE_INGREDIENT } from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';
import IngredientModal from '../ingredient-modal/ingredient-modal.vue';
import { appLocalStorage } from '../../../shared/services';

export default {
  name: 'IngredientsTable',
  components: { AppTable, IngredientModal },
  mixins: [notifyMixin],
  data() {
    return {
      page: 1,
      fields: [
        { label: '#', value: 'id' },
        { label: 'Название', value: 'title' },
        { label: 'Категория', value: 'category.title' },
        { label: 'Ед. измерения', value: 'unit.title' },
      ],
      rules: [
        {
          value: 'edit',
          type: 'primary',
          icon: 'el-icon-edit',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ],
      modal: {
        show: false,
        title: null,
      },
      ingredient: null,
      query: {},
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.ingredientsState.ingredientsData.loading,
      data: (state) => state.ingredientsState.ingredientsData.data,
      error: (state) => state.ingredientsState.ingredientsData.error,
    }),
  },
  watch: {
    async page(page) {
      await this.fetch({ page, filter: this.$route.query });
    },
    async $route(to) {
      await this.fetch({ page: this.page, filter: to.query });
    },
  },
  async created() {
    const queryParams = this.$route.query;
    if (queryParams.title) {
      this.query = { ...this.query, title: queryParams.title };
    }
    if (queryParams['category.title']) {
      this.query = { ...this.query, 'category.title': queryParams['category.title'] };
    }
    await this.fetch({ page: this.page, filter: this.query });
  },
  methods: {
    ...mapActions({
      fetch: FETCH_INGREDIENTS,
      delete: DELETE_INGREDIENT,
    }),
    changePage(page) {
      this.page = page;
    },
    handleEdit(ingredient) {
      appLocalStorage.setItem('ingredient', ingredient);
      this.$router.push(`${this.$route.path}/${ingredient.id}`);
    },
    handleDelete(ingredients) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete(ingredients.id);
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Ингредиент успешно удален');
        })
        .catch(() => {});
    },
  },
};
</script>
