<template>
  <div>
    <ingredients-filter />
    <ingredient-table />
  </div>
</template>

<script>
import IngredientsFilter from './ingredients-filter/ingredients-filter.vue';
import IngredientTable from './ingredient-table/ingredient-table.vue';

export default {
  name: 'Ingredients',
  comments: {},
  components: {
    IngredientTable,
    IngredientsFilter,
  },
  data() {
    return { showModal: false };
  },
  async mounted() {
    await this.$store.dispatch('FETCH_INGREDIENTS', {});
  },
  methods: {
    changeItem(e) {
      this.ingredient.unit_id = e;
      const bup = this.ingredient.title;
      this.ingredient.title = `${bup} `;
      this.ingredient.title = bup;
    },
    handleAddIngredient() {
      this.showAddIngredient = true;
    },

    handleClose() {
      this.showAddIngredient = false;
      this.ingredient = { title: '' };
      this.title = 'Добавить ингредиент';
    },
    async sendDataIngredients() {
      this.loading = true;
      if (this.ingredient.id) {
        try {
          await this.$store.dispatch('UPDATE_INGREDIENT', this.ingredient);
          this.$notify({
            title: 'Успешно',
            message: 'Ингредиент успешно обновлен',
            duration: 1500,
            type: 'success',
          });
        } catch (error) {
          this.$notify.error({
            title: 'Ошибка',
            message: 'Ошибка',
            duration: 1500,
          });
        }
        this.handleClose();
        this.loading = false;
        return true;
      }

      this.handleClose();
      this.loading = false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-menu {
  display: flex;
  justify-content: space-between;
}
</style>
