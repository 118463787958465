<template>
  <el-dialog
    :title="title"
    :modal-append-to-body="false"
    :append-to-body="true"
    :visible.sync="visible"
    width="40%"
  >
    <el-form
      ref="ingredients"
      :model="data"
      :rules="actions === 'create' ? formRules : null"
      label-position="top"
      @submit.prevent.native="saveFn"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Название" prop="title">
            <el-input v-model="data.title" autocomplete="off" placeholder="Мясо" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Штрихкод" prop="barcode">
            <el-input v-model="data.barcode" placeholder="9057383582" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Единицы измерения" prop="unit_id">
            <el-autocomplete
              v-model="data.unit.title"
              style="width: 100%"
              :fetch-suggestions="searchUnitFn"
              :debounce="600"
              placeholder="Категорию ингредиента"
              value-key="title"
              @select="selectUnitFn"
              @input="inputUnit"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Категория ингредиента" prop="category_id">
            <el-autocomplete
              v-model="data.category.title"
              style="width: 100%"
              :fetch-suggestions="searchCategoryFn"
              :debounce="600"
              placeholder="Категорию ингредиента"
              value-key="title"
              :select-when-unmatched="true"
              @select="selectCategoryFn"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Калории">
            <el-input v-model="data.calories" placeholder="Калории" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Белки">
            <el-input v-model="data.proteins" placeholder="Белки" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Жиры">
            <el-input v-model="data.fats" placeholder="Жиры" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Углеводы">
            <el-input v-model="data.carbohydrates" placeholder="Углеводы" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="% потерь при запекании" prop="losses_baking">
            <el-input-number v-model="data.losses_baking" :min="0" :max="100" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="% потерь при варке" prop="losses_cooking">
            <el-input-number v-model="data.losses_cooking" :min="0" :max="10000" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="% потерь при жарке" prop="losses_frying">
            <el-input-number v-model="data.losses_frying" :min="0" :max="100" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="% потерь при отчистке" prop="losses_cleaning">
            <el-input-number v-model="data.losses_cleaning" :min="0" :max="10000" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="% потерь при тушении" prop="losses_stew">
            <el-input-number v-model="data.losses_stew" :min="0" :max="10000" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="primary" :loading="loading" @click="saveFn"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { FETCH_UNITS } from '@/app/settings/units/shared/state/types/action_types';
import {
  STORE_INGREDIENT,
  UPDATE_INGREDIENT,
  FETCH_INGREDIENT_CATEGORY,
} from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'IngredientsModal',
  mixins: [notifyMixin],
  props: {
    title: {
      type: String,
      default: 'Добавить ингредиент',
    },
    show: {
      type: Boolean,
      default: false,
    },
    ingredient: {
      type: Object,
      default: null,
    },
    actions: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      loading: false,
      defaultData: {
        title: '',
        losses_baking: '',
        losses_cooking: '',
        losses_frying: '',
        losses_cleaning: '',
        losses_stew: '',
        unit_id: null,
        barcode: '',
        category_id: null,
        unit: {
          title: null,
        },
        category: {
          title: null,
        },
      },
      formRules: {
        title: [{ required: true, message: 'Введите название', trigger: 'blur' }],
        unit_id: [{ required: true, validator: this.validateUnitId, trigger: 'change' }],
        category_id: [
          {
            required: true,
            validator: this.validateCategoryId,
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    data: {
      get() {
        return this.ingredient ? this.ingredient : this.defaultData;
      },
      set(value) {
        this.$emit('update:interaction', value);
      },
    },
    ...mapState({
      categories: (state) => state.ingredientsState.ingredientsData.category,
      error: (state) => state.ingredientsState.ingredientsData.error,
      units: (state) => state.unitsState.unitsData.data,
    }),
  },
  watch: {
    visible(value) {
      if (value) {
        document.getElementById('main').classList.add('overflow-hidden');
        return;
      }
      document.getElementById('main').classList.remove('overflow-hidden');
    },
    error(value) {
      if (value.response) {
        if (this.$refs.ingredients) {
          const errorField = Object.keys(value.response.data.errors);
          errorField.forEach((field) => this.$refs.ingredients.validateField(field));
        }
      }
    },
  },
  methods: {
    ...mapActions({
      store: STORE_INGREDIENT,
      update: UPDATE_INGREDIENT,
      fetchCategories: FETCH_INGREDIENT_CATEGORY,
      fetchUnits: FETCH_UNITS,
    }),
    saveFn() {
      this.$refs.ingredients.validate(async (valid) => {
        if (valid) {
          if (this.data.id) {
            await this.update(this.data);
            if (!this.error) {
              this.visible = false;
              this.showSuccessMessage();
            }
          } else {
            await this.store(this.data);
            if (!this.error) {
              this.visible = false;
              this.showSuccessMessage();
            }
          }
          return true;
        }
        return false;
      });
    },
    validateUnitId(rule, value, callback) {
      if (!value) {
        return callback(new Error('Пожалуйста укажите ед. измерения'));
      }
      if (this.error) {
        if (this.error.response.data.errors.unit_id) {
          return callback(new Error('Пожалуйста укажите ед. измерения'));
        }
      }
      return callback();
    },
    validateCategoryId(rule, value, callback) {
      if (!value) {
        return callback(new Error('Пожалуйста укажите категорию'));
      }
      if (this.error) {
        if (this.error.response.data.errors.category_id) {
          return callback(new Error('Пожалуйста укажите категорию'));
        }
      }
      return callback();
    },
    selectCategoryFn(category) {
      this.data.category_id = category.id;
    },
    inputUnit() {
      if (!this.data.unit.title) {
        this.data.unit_id = null;
      }
    },
    async searchCategoryFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString }, limit: 100 } : { limit: 100 };
      await this.fetchCategories(filter);
      cb(this.categories);
    },
    selectUnitFn(unit) {
      this.data.unit_id = unit.id;
    },
    async searchUnitFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString }, limit: 100 } : { limit: 100 };
      await this.fetchUnits(filter);
      cb(this.units.data);
    },
  },
};
</script>
