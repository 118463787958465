<template>
  <div class="guide-filter">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-badge is-dot :hidden="isActiveFilter" class="item">
          <el-button icon="el-icon-set-up" @click="openFilter"> Фильтр </el-button>
        </el-badge>
      </el-col>
      <el-col :span="12">
        <div class="is-flex is-justify-content-flex-end">
          <el-button type="primary" icon="el-icon-plus" @click="showModal = true">
            Добавить ингредиент
          </el-button>
        </div>
      </el-col>
    </el-row>
    <div v-show="isShowFilter" class="filter-panel mt-4">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input v-model="title" prefix-icon="el-icon-search" placeholder="Поиск по названию" />
        </el-col>
        <el-col :span="8">
          <el-autocomplete
            v-model="category"
            style="width: 100%"
            :fetch-suggestions="searchCategoryFn"
            :debounce="600"
            prefix-icon="el-icon-search"
            placeholder="Поиск по категории"
            value-key="title"
          />
        </el-col>
      </el-row>
    </div>
    <ingredient-modal :show.sync="showModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_MEALS } from '@/app/settings/meals/shared/state/types/action_types';
import { DATA as DATA_MEALS } from '@/app/settings/meals/shared/state/types/getter_types';
import { FETCH_INGREDIENTS, FETCH_INGREDIENT_CATEGORY } from '../shared/state/types/action_types';
import { CATEGORY } from '../shared/state/types/getter_types';
import IngredientModal from '../ingredient-modal/ingredient-modal.vue';

export default {
  name: 'IngredientFilter',
  components: { IngredientModal },
  data() {
    return {
      isShowFilter: false,
      isActiveFilter: true,
      category: '',
      title: null,
      showModal: false,
      query: {},
    };
  },
  computed: {
    ...mapGetters({ categories: CATEGORY, meals: DATA_MEALS }),
    path() {
      return this.$route.path;
    },
  },
  watch: {
    query(query) {
      if (query.title === '') {
        delete query.title;
      }
      if (query['category.title'] === '') {
        delete query['category.title'];
      }
      this.$router.push({ path: this.path, query }).catch(() => {});
    },
    title(value) {
      this.query = { ...this.query, title: value };
    },
    category(value) {
      this.query = { ...this.query, 'category.title': value };
    },
  },
  created() {
    const queryParams = this.$route.query;
    if (queryParams.title) {
      this.title = queryParams.title;
      this.openFilter();
    }
    if (queryParams['category.title']) {
      this.category = queryParams['category.title'];
      this.openFilter();
    }
  },
  methods: {
    ...mapActions({
      fetch: FETCH_INGREDIENTS,
      fetchCategories: FETCH_INGREDIENT_CATEGORY,
      fetchMeals: FETCH_MEALS,
    }),
    openFilter() {
      this.isShowFilter = !this.isShowFilter;
      this.isActiveFilter = !this.isActiveFilter;
    },
    async searchCategoryFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString } } : {};
      await this.fetchCategories(filter);
      cb(this.categories);
    },
  },
};
</script>
